.layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    box-sizing: border-box;

    /* NEW: constrain total width and add margin on small screens */
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 5vw;
    padding-right: 5vw;
}


.projects-description {
    flex: 1; /* Allow CV section to take up 30% of the space */
    min-width: 30%; /* Ensures CV section takes up 70% initially */
    padding-right: 20px; /* Add some padding to separate from blog section */
    border-right: 1px solid black; /* Subtle border to separate sections */
    box-sizing: border-box; /* Include padding and border in width calculations */
}

.projects-content {
    flex: 1; /* Allow Blog section to take up 70% of the space */
    flex-direction: column; /* Stack elements vertically */
    min-width: 70%; /* Ensures Blog section takes up 30% initially */
    padding-left: 20px;
    box-sizing: border-box; /* Include padding and border in width calculations */
}

@media (max-width: 900px) {
    .layout {
        flex-direction: column;
        align-items: stretch;
    }

    .projects-description,
    .projects-content {
        min-width: unset;
        width: 100%;
        padding: 10px 0;
        border: none;
    }

    .projects-content {
        padding-top: 0;
    }
}
