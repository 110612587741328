.maze-widget {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: none;
    max-width: 600px;
    margin: auto;
}
canvas {
    border: 1px solid #ccc;
    margin-top: 10px;
}

.ms-text {
    font-size: 14px;
    text-align: center;
    margin-bottom: 8px;
    color: black; /* White text color for the body */
}