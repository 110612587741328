.project-box {
    position: relative;
    width: calc(100% - 20px);
    margin: 20px auto;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center everything horizontally */
    padding: 8px 16px;
    border: 5px solid white;
    background-color: transparent;
    box-sizing: border-box;
}

.text-title {
    font-size: 18px;
    font-weight: bold;
    color: white;
    text-align: left;
}

.text-date {
    font-size: 10px;
    color: lightgrey;
    text-align: right;
}

.text-body {
    font-size: 14px;
    text-align: center;
    margin-bottom: 8px;
    color: white;
}

.text-link {
    color: blue;
    text-decoration: underline;
    margin-bottom: 8px;
    text-align: center;
}

.italic-line {
    font-style: italic;
    font-size: 12px;
    color: white;
    text-align: center;
    margin-top: 8px;
}

.project-addon {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}

@media (max-width: 600px) {
    .project-box {
        padding: 12px;
        border-width: 3px;
    }

    .text-title {
        font-size: 16px;
    }

    .text-body {
        font-size: 13px;
    }

    .text-link {
        font-size: 12px;
    }

    .maze-widget {
        padding: 10px;
    }

    canvas {
        width: 100%;
        height: auto;
    }
}
